import React from 'react'

import { List, ListItem, Paragraph, Tooltip } from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'
import { TooltipExample } from '../../examples/react/TooltipExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Tooltip"
    components={[{ component: Tooltip }]}
  >
    <Section title="Saavutettavuus">
      <Paragraph noMargin>
        Saavutettavuuden näkökulmasta Tooltip on ongelmallinen komponentti. Sen
        käyttöä on syytä harkita tarkoin, eikä sen avulla tule näyttää mitään
        käyttäjälle oleellista tietoa.
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          Hover-toiminnallisuus ei ole mahdollinen näppäimistöllä, eikä
          tavalliseen tekstiin saada focusta. Tooltip-elementtiin sijoitettu
          teksti jää siis ruudunlukijakäyttäjän ulottumattomiin.
        </ListItem>
        <ListItem>
          Myös näkevältä käyttäjältä tooltip-toiminnallisuus saattaa jäädä
          huomaamatta ilman visuaalista vihjettä.
        </ListItem>
      </List>
      <Paragraph noMargin>
        Tooltip-komponentissa saavutettavuutta on parannettu lisäämällä
        tooltip-elementtiin sijoitettu teksti lisäksi VisuallyHidden-elementtinä
        DOMiin.
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          Näin ruudunlukija lukee tooltipin sisällön heti siihen liitetyn
          tekstin perään.
        </ListItem>
        <ListItem>
          Käyttäjä ei kuitenkaan voi valita, haluaako kuulla lisätietotekstiä,
          vaan se luetaan joka tapauksessa, joten tooltipiin lisättävän tekstin
          on hyvä olla mahdollisimman lyhyt.
        </ListItem>
      </List>
      <Paragraph noMargin>
        Tooltipin sijasta lisätekstin voi tuoda saataville esim. erillisellä
        lisätieto-painikkeella, jonka saa avattua myös näppäinkomennoilla.
      </Paragraph>
    </Section>

    <Section title="Esimerkkejä">
      <Paragraph>
        <Code>Tooltip</Code>-komponentilla voi näyttää tekstisisältöä toisen
        komponentin ohessa. Teksti on näkyvissä, kun kursori on komponentin
        päällä.
      </Paragraph>
      <Playground
        example={TooltipExample}
        enableOverflow
      />
    </Section>
  </Content>
)

export default Page
