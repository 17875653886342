import React from 'react'

import { Badge, Text, Tooltip } from '@te-digi/styleguide'

const text = (
  <Text>
    Muokattu: 23.3.2023
    <br />
    Organisaatio: Tilastokeskus
    <br />
    Järjestelmä: STAT
  </Text>
)

const TooltipExample = () => (
  <Tooltip content={text}>
    <Badge>Y-ATV</Badge>
  </Tooltip>
)

export { TooltipExample }
